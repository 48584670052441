
<template>
    <div @click="inspectViewStatus = false">
      <CSDialog :dialog-title="`${addInspectionRouteInfo.routeId != null ? '修改' : '添加'}巡检路线`"
        @onConfirm="saveInspectionRouteInfo" @onClose="dialogVisible = false" :dialog-visible="dialogVisible"
        dialogWidth="610px" :is-submitting="isSubmitting"
      >
        <template v-slot:dialog-content>
          <div class="ibox-content"  style="padding: 27px 30px 30px 30px" @click="inspectViewStatus = false">
              <div class="form-group row">
                <label style="width: 96px;text-align: right; margin-right: 30px"
                >巡检路线</label
                >
                <div style="width: 400px; padding-left: 0">
                  <input
                      v-model="addInspectionRouteInfo.name"
                      type="text"
                      placeholder="请输入"
                      style="width: 400px; border-radius: 4px; border: 1px solid #979797; padding-left: 10px"
                  />
                </div>
              </div>
              <div class="form-group row">
                  <label style="width: 96px;text-align: right; margin-right: 30px"
                  >巡检点</label
                  >
                  <div class="col-sm-9" style="padding: 0">
                    <div
                        v-for="(item, index) in checkedInspectPoints"
                        draggable="true"
                        @drop="drop($event, index)"
                        @dragover="allowDrop"
                        @dragstart="startDrag($event, index)"
                        :key="item.id"
                        style="position: relative; margin-bottom: 10px; width: 400px; "
                    >
                      <div style="width: 333px; overflow: hidden; white-space: nowrap;text-overflow: ellipsis;">
                        {{ item.name }}
                      </div>
                      <span
                          class="glyphicon glyphicon-menu-hamburger"
                          style="text-align: right; line-height:36px; right: 30px; position: absolute"
                          aria-hidden="true"
                      ></span>
                      <svg
                          class="icon"
                          aria-hidden="true"
                          @click="removeCheckedInspectPoints(index)"
                          style="text-align: right; line-height:36px; right: 0px; position: absolute; top: 7px;"
                      >
                        <use xlink:href="#icon-menushanchu"></use>
                      </svg>
                    </div>
                    <div class="row" style="position: relative;">
                      <div
                          style="position: relative;padding-left: 0;"
                      >
                        <CSSelect
                            class="add-inspection-point"
                            height="40px"
                            i-width="36px"
                            border="1px solid #979797"
                        >
                          <input
                              type="text"
                              class="add-inspection-point-input"
                              placeholder="选择巡检点"
                              style="width: 265px; padding-left: 10px"
                              :value="getTemporaryName(temporaryPoints)"
                              @click.stop="inspectViewStatus = !inspectViewStatus"
                          />
                          <div
                              @click.stop
                              v-if="inspectViewStatus"
                              class="add-inspection-point-panel"
                              style="margin-top: 17px; width: 400px; overflow-y: hidden;"
                          >
                            <div>
                              <input
                                  placeholder="搜索巡检点"
                                  type="text"
                                  v-model="inspectPointKeyword"
                                  style="vertical-align: middle; font-size: 20px; height: 40px; padding: 0 20px 0 10px; border-radius: 4px; border: 1px solid #979797; width: 290px"
                              />
                              <button
                                  class="btn btn-primary btn-small"
                                  style="
                                      height: 40px;
                                      width: 60px;
                                      padding: 0;
                                      vertical-align: middle;
                                      min-width: 60px;
                                      font-size: 20px;
                                      margin-left: 20px;
                                      border-radius: 6px;
                                  "
                                  @click.stop="_listInspectionPoints"
                              >
                                查询
                              </button>
                            </div>
                            <select
                                style="
                                    width: 100%;
                                    font-size: 20px;
                                    height: 200px;
                                    color: #000;
                                "
                                multiple
                                v-model="temporaryPoints"
                                @change="inspectViewStatus = false"
                            >
                              <option
                                  v-for="(point,pIndex) in inspectPoints"
                                  :value="point"
                                  :key="pIndex"
                                  style="color: #000; border-bottom: 1px solid #ccc; padding: 15px 0; overflow: hidden; white-space: nowrap;text-overflow: ellipsis;"
                              >
                                {{
                                  point.name + ' 位置:' + point.addr
                                }}
                              </option>
                            </select>
                          </div>
                        </CSSelect>
                      </div>
                      <div style="">
                        <button
                            class="btn btn-primary"
                            style="font-size: 24px; position: absolute; top: 2px; right: 11px; border-radius: 6px; min-width: 70px; width: 70px; padding: 0; "
                            @click="addInspectPoint"
                        >
                          添加
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </template>
      </CSDialog>
    </div>
</template>
<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import {
    queryInspectionPointUrl,
    createInspectRouteUrl,
    editInspectRouteUrl,
} from "@/requestUrl";

export default {
    props: { callBackListener: String, callBackFunction: String },
    components: {
      CSSelect,
      CSDialog,
    },
    data() {
        return {
            inspectViewStatus: false,
            isSubmitting: false,
            dialogVisible: false,
            inspectPointKeyword: "",
            inspectPoints: [],
            temporaryPoints: [],
            checkedInspectPoints: [],
            addInspectionRouteInfo: {
                name: "",
                inspectPoints: "",
                operatorId: this.$vc.getCurrentStaffInfo().id,
                regionId: this.$vc.getCurrentRegion().communityId,
            },
        };
    },

    mounted() {
        this._listInspectionPoints();
        this.$vc.on(this.$route.path,
            "addInspectionRoute",
            "openAddInspectionRouteModal",
            (params) => {
                this.dialogVisible = true;
                this.inspectViewStatus = false;
                const staffInfo = this.$vc.getCurrentStaffInfo();
                if (params) {
                    this.addInspectionRouteInfo = {
                        name: params.name,
                        routeId: params.id,
                        inspectPoints: "",
                        operatorId: staffInfo.id,
                        regionId: this.$vc.getCurrentRegion().communityId,
                    };
                    this.checkedInspectPoints = params.inspectPoints;
                    this.temporaryPoints = [];
                } else {
                    this.checkedInspectPoints = [];
                    this.temporaryPoints = [];
                    this.addInspectionRouteInfo = {
                        name: "",
                        inspectPoints: "",
                        createBy: staffInfo.id,
                        orgId: staffInfo.departmentCode,
                        regionId: this.$vc.getCurrentRegion().communityId,
                    };
                }
            }
        );
    },
    methods: {
        startDrag(e, index) {
            e.dataTransfer.setData("pointIndex", index);
        },
        drop(e, index) {
            this.allowDrop(e);
            //使用一个新数组重新排序后赋给原变量
            let arr = this.checkedInspectPoints.concat([]),
                dragIndex = e.dataTransfer.getData("pointIndex"),
                temp = arr.splice(dragIndex, 1);

            arr.splice(index, 0, temp[0]);
            this.checkedInspectPoints = arr;
        },
        allowDrop(e) {
            e.preventDefault();
        },
        removeCheckedInspectPoints(index) {
            this.checkedInspectPoints.splice(index, 1);
        },
        getTemporaryName(temporaryPoints) {
            let temporaryPointsName = [];
            temporaryPoints.forEach((val) => {
                temporaryPointsName.push(val.name + " 位置:" + val.addr);
            });
            return temporaryPointsName.toString();
        },
        addInspectPoint() {
            this.checkedInspectPoints.push(...this.temporaryPoints);
            this.inspectViewStatus = false;
            this.temporaryPoints = [];
        },
        _listInspectionPoints: function () {
            let { orgId, id: operatorId } = this.$vc.getCurrentStaffInfo();
            let param = {
                regionId: this.$vc.getCurrentRegion().communityId,
                buildingId: "",
                keyword: this.inspectPointKeyword,
                pageNum: 1,
                pageSize: 50,
                orgId,
                operatorId,
            };

            this.$fly.post(queryInspectionPointUrl, param).then((res) => {
                if (res.code !== 0) {
                    return;
                }
                this.inspectPoints = res.data.datas;
            });
        },
        saveInspectionRouteInfo: function () {

            this.addInspectionRouteInfo.regionId = this.$vc.getCurrentRegion().communityId;
            let checkedInspectPoints = [];
            this.checkedInspectPoints.forEach((val) => {
                checkedInspectPoints.push(val.id);
            });

            if(!this.addInspectionRouteInfo.name) {
                this.$vc.message('请输入巡检路线名称')
                return ;
            }
            if (checkedInspectPoints.length === 0) {
              this.$vc.message('请选择巡检点')
              return ;
            }

            this.addInspectionRouteInfo.inspectPoints = this.$vc.arrToString(
                checkedInspectPoints
            );
            //不提交数据将数据 回调给侦听处理
            if (this.$vc.notNull(this.callBackListener)) {
                this.$vc.emit(this.$route.path,
                    this.callBackListener,
                    this.callBackFunction,
                    this.addInspectionRouteInfo
                );
                this.dialogVisible = false;
                return;
            }
            this.isSubmitting = true;
            this.$fly
                .post(
                    this.addInspectionRouteInfo.routeId
                        ? editInspectRouteUrl
                        : createInspectRouteUrl,

                    this.addInspectionRouteInfo
                )
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }

                    this.$vc.toast("操作成功");
                    this.dialogVisible = false;
                    this.clearAddInspectionRouteInfo();
                    this.$vc.emit(this.$route.path,
                        "inspectionRouteManage",
                        "listInspectionRoute",
                        {}
                    );
                })
            .finally(() => this.isSubmitting = false)
        },
        clearAddInspectionRouteInfo: function () {
            this.addInspectionRouteInfo = {
                name: "",
                inspectPoints: "",
                orgId: this.$vc.getCurrentStaffInfo().orgId,
                regionId: this.$vc.getCurrentRegion().communityId,
            };
        },
    },
};
</script>
<style lang="stylus" scoped>
.ibox-content
  font-size 24px
  color #444
  padding-bottom 0
  .col-form-label
    font-size 24px
    text-align right
    color #444
  input.form-control
    border-radius 6px
    font-size 24px
    height 40px
    box-sizing border-box
  .cs-select.add-inspection-point
    border-color #e5e6e7
    .add-inspection-point-input
      border none
      outline none
    .add-inspection-point-panel
      width 400px
      border 1px solid #e5e6e7
      border-radius 5px
      padding 10px
      max-height 260px
      overflow-y auto
      position absolute
      top 34px
      left 0
      background #fff
      z-index 1
      input
        border1px solid #e5e6e7
        outline none
      select
        margin-top 10px
    .icon
      border-color #e5e6e7
</style>
